import constClass from '../../Constants/Constants';
import Common from '../Common/common';
import moment from 'moment';
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

const TicketIssue = ({ ticket,ticketIssueClick, backClick, disabled, siteId, ticketId, customerId, issueCount, lineId, ticket_issue_all_sum_list }) => {

  const [selectedTicket, setSelectedTicket] = useState(null);
  const sysdateTimestamp = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0)

  const refreshData = useCallback(async () => {
    if (!ticket.ticket_id) {
      const selectedticket = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/ticket_issue/line/${siteId}/${ticketId}/${customerId}/${issueCount}`, { line_id: lineId })).data;
      setSelectedTicket(selectedticket);
    }
    else {
      setSelectedTicket(ticket)
    }
  }, [ticket, siteId, ticketId, customerId, issueCount, lineId]);

  useEffect(() => {
    refreshData()
  }, [refreshData]);

  return (
    <div id="ticketUse" className="px-0-env">
    {selectedTicket &&
      <div className="mx-3 mt-1 mb-3 bg-white border">
        <div className="row mx-0 px-3-env pt-2 pb-1 card-header">
          <div className="col py-1 px-1 align-self-center text-center">
            <h4 className="p-0 m-0">{selectedTicket.ticket_name}</h4>
            {selectedTicket.ticket_summary && <span className="p-0 m-0">{selectedTicket.ticket_summary}</span>}
          </div>
        </div>
            {!selectedTicket.use_date && sysdateTimestamp > new Date(selectedTicket.limit_date) &&
              <div className="row mx-0 px-3 pt-2 pb-1">
                <div className="col py-1 px-1 align-self-center text-center">
                  <p
                    className="mb-0 py-1 text-danger w-100">
                    このクーポンは有効期限切れです
                  </p>
                </div>
              </div>
            }
            {selectedTicket.use_date &&
              <div className="row mx-0 px-3 pt-2 pb-1">
                <div className="col py-1 px-1 align-self-center text-center">
                  <p
                    className="mb-0 py-1 text-danger w-100">
                    このクーポンは使用済みです
                  </p>
                </div>
              </div>
            }
            {selectedTicket.invalid_flag === constClass.FLAG.ON &&
              <div className="row mx-0 px-3 pt-2 pb-1">
                <div className="col py-1 px-1 align-self-center text-center">
                  <p
                    className="mb-0 py-1 text-danger w-100">
                    このクーポンは無効です
                  </p>
                </div>
              </div>
            }
            {selectedTicket.first_come_served > 0 && ticket_issue_all_sum_list.find(t => t.ticket_id === ticket.ticket_id) && Number(ticket_issue_all_sum_list.find(t => t.ticket_id === ticket.ticket_id).total_ticket_issue_times) >= ticket.first_come_served &&
              <div className="row mx-0 px-3 pt-2 pb-1">
                <div className="col py-1 px-1 align-self-center text-center">
                  <p
                    className="mb-0 py-1 text-danger w-100">
                    このクーポンは配布終了済みです
                  </p>
                </div>
              </div>
            }
        <div className="row mx-0 px-3 pt-2 pb-1 text-alert">
          {(selectedTicket.ticket_image_url && (selectedTicket.use_date || selectedTicket.invalid_flag === constClass.FLAG.ON || sysdateTimestamp > new Date(selectedTicket.limit_date) || (selectedTicket.first_come_served && selectedTicket.first_come_served > 0 && ticket_issue_all_sum_list.find(t => t.ticket_id === ticket.ticket_id) && Number(ticket_issue_all_sum_list.find(t => t.ticket_id === ticket.ticket_id).total_ticket_issue_times) >= ticket.first_come_served))) &&
            <div className="col py-1 px-1 align-self-center text-center">
              {!selectedTicket.hp_url && <div className="w-50 mx-auto ticketuse"><img className="w-100" src={`/${siteId}/${constClass.TICKET_IMAGE_DIR}${selectedTicket.ticket_image_url}`} alt={selectedTicket.ticket_name} /></div>}
              {selectedTicket.hp_url &&
                <a href={Common.getExternalUrl(selectedTicket.hp_url)} target="_blank" rel="noreferrer" className="text-digitalcard">
                    <div className='ticketuse'>
                      <img className="w-50" src={`/${siteId}/${constClass.TICKET_IMAGE_DIR}${selectedTicket.ticket_image_url}`} alt={selectedTicket.ticket_name} />
                    </div>
                  <br />
                  <u>公式サイトへ</u>
                </a>
              }
            </div>
          }
          {selectedTicket.ticket_image_url && !selectedTicket.use_date && selectedTicket.invalid_flag !== constClass.FLAG.ON && sysdateTimestamp <= new Date(selectedTicket.limit_date) &&
            <div className="col py-1 px-1 align-self-center text-center">
              {!selectedTicket.hp_url && <div className="w-50 mx-auto"><img className="w-100" src={`/${siteId}/${constClass.TICKET_IMAGE_DIR}${selectedTicket.ticket_image_url}`} alt={selectedTicket.ticket_name} /></div>}
              {selectedTicket.hp_url &&
                <a href={Common.getExternalUrl(selectedTicket.hp_url)} target="_blank" rel="noreferrer" className="text-digitalcard">
                    <img className="w-50" src={`/${siteId}/${constClass.TICKET_IMAGE_DIR}${selectedTicket.ticket_image_url}`} alt={selectedTicket.ticket_name} />
                  <br />
                  <u>公式サイトへ</u>
                </a>
              }
            </div>
          }
        </div>
        <div className="row mx-0 px-3 pt-1 pb-1">
          <div className="col py-1 px-1 align-self-center">
            <div className="row pb-3">
              <div className="col text-center">
                <span className="p-0 m-0">{Common.getBrString(selectedTicket.ticket_detail)}</span>
              </div>
            </div>
            {selectedTicket.ticket_notes &&
              <div className="row pb-1">
                <div className="col small">
                  【注意事項】
                  <span className="p-0 m-0">{Common.getBrString(selectedTicket.ticket_notes)}</span>
                </div>
              </div>
            }
            <div className="row pb-3">
              <div className="col small">
                【有効期限】
                <br />
                <span className="p-0 m-0">{Common.getDateObject1(selectedTicket.limit_date).str}</span>
              </div>
            </div>
            {/* {ticket.ticket_times > 1 &&
              <div className="row pb-1">
                <div className="col text-center small">
                  <span className="p-0 m-0">最大{ticket.ticket_times}回使用可能({ticket.t_ticket_use.length > 0 ? ticket.t_ticket_use.length + '回使用済み' : '未使用'})</span>
                </div>
              </div>
            } */}
            <div className="row pb-3">
              <div className="col">
                {!selectedTicket.use_date && selectedTicket.invalid_flag !== constClass.FLAG.ON && sysdateTimestamp <= new Date(selectedTicket.limit_date) &&
                  (selectedTicket.first_come_served && selectedTicket.first_come_served > 0 ? ticket_issue_all_sum_list.find(t => t.ticket_id === ticket.ticket_id) && Number(ticket_issue_all_sum_list.find(t => t.ticket_id === ticket.ticket_id).total_ticket_issue_times) < selectedTicket.first_come_served : true) && (
                    <div className="text-center">
                      <p className="mb-0 py-1 text-alert w-100">
                        この画面をスタッフへご提示ください
                      </p>
                    </div>
                  )
                }
                {selectedTicket.use_date &&
                  <div className="row p-0 m-0">
                    <div className="col-4 p-0 m-0 text-right">
                      使用日時
                    </div>
                    <div className="col-8 p-0 m-0 text-left">
                      {moment(selectedTicket.use_date).format("YYYY/MM/DD HH:mm:ss")}
                    </div>
                  </div>
                }
              </div>
            </div>
            <div className="row">
              <div className="col">
                <button
                  className="btn btn-enable w-100"
                  disabled={disabled}
                  onClick={backClick}>
                  戻る
                </button>
              </div>
            </div>
          {(!selectedTicket.use_date && selectedTicket.invalid_flag !== constClass.FLAG.ON && sysdateTimestamp <= new Date(selectedTicket.limit_date) &&
            (selectedTicket.first_come_served && selectedTicket.first_come_served > 0 ? ticket_issue_all_sum_list.find(t => t.ticket_id === ticket.ticket_id) && Number(ticket_issue_all_sum_list.find(t => t.ticket_id === ticket.ticket_id).total_ticket_issue_times) < selectedTicket.first_come_served : true)) && (
              <React.Fragment>
                <div className="row pt-3 mt-3 border-top">
                  <div className="col">
                    <div className="text-center">
                      <p className="mb-0 py-1 text-secondary w-100">
                        以下のボタンは店舗用です。<br />
                        タップするとクーポンを使用します。
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div>
                      <button onClick={ticketIssueClick(selectedTicket.issue_count, selectedTicket.ticket_id)} className="btn btn-secondary w-100">
                        クーポンを使用する
                      </button>
                      </div>
                    </div>
                  </div>
                  {selectedTicket.first_come_served > 0 &&
                    <div className="row pt-1 mt-1">
                      <div className="col">
                        <div className="text-center">
                          <p
                            className="mb-0 py-1 text-alert w-100">
                            ※ボタン押下可能な状況であっても、定員の上限に達している場合には、クーポンをご利用いただけないことがございます。
                          </p>
                        </div>
                      </div>
                    </div>
                  }
                </React.Fragment>
            )
          }
          </div>
        </div>
      </div>
    }
    </div>
  )
}

export default TicketIssue;